define("discourse/plugins/Moderatori Plugin/discourse/initializers/custom-initializer", ["exports", "discourse/lib/plugin-api", "discourse/plugins/Moderatori Plugin/discourse/components/modal/custom-palette"], function (_exports, _pluginApi, _customPalette) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "custom-initializer",
    initialize() {
      (0, _pluginApi.withPluginApi)(_pluginApi.PLUGIN_API_VERSION, api => {
        console.log("Inizializzo plugin");

        // DA GITHUB
        const hasAlpha = /(.,){3}|\//;
        const MAX_LENGTH = 25;
        const getVariable = value => {
          const color = value.replace(/\s/g, "");
          return hasAlpha.test(color) || color.length > MAX_LENGTH ? "" : color;
        };
        api.onToolbarCreate(toolbar => {
          toolbar.addButton({
            id: "color_ui_button",
            group: "extras",
            icon: "palette",
            title: "Color the text",
            perform: e => {
              api.container.lookup("service:modal").show(_customPalette.default, {
                model: {
                  toolbarEvent: {
                    addText: text => {
                      e.applySurround(`[bgcolor=${text}]`, "[/bgcolor]", "placeholder_coloured_text");
                    }
                  }
                }
              });
            }
          });
        });
        api.decorateCookedElement(post => {
          post.querySelectorAll("[data-color]").forEach(i => i.style.setProperty("--color", getVariable(i.dataset.color)));
          post.querySelectorAll("[data-bgcolor]").forEach(i => i.style.setProperty("--bgcolor", getVariable(i.dataset.bgcolor)));
        }, {
          id: "wrap-colors"
        });
      });
    }
  };
});